import CookieLaw from "vue-cookie-law";
export default {
  name: "home",
  components: {
    CookieLaw,
  },
  props: [],
  data() {
    return {};
  },
  computed: {},
  mounted() {},
  methods: {},
};
